<template>
  <div v-if="isDataLoadedFromServer">
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      persistent
      max-width="1000"
    >
      <!--begin::Modal dialog-->
      <div class="bg-white poppins">
        <!--begin::Modal header-->
        <div class="modal-header py-4 align-center">
          <h4 class="mb-0 font-weight-bolder">Edit Store</h4>
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-light-info"
            @click="dialog = false"
          >
            <span class="svg-icon">
              <v-icon size="22">mdi-close</v-icon>
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--begin::Modal header-->
        <!--begin::Modal body-->
        <div
          class="modal-body d-flex flex-column h-550px scroll-y mx-5 mx-xl-15 py-7"
        >
          <!--begin::Body-->

          <form id="editForm">
            <v-select
              v-model="formData.customer_id"
              label="Customer"
              item-text="text"
              item-value="index"
              :items="serverData.clients"
              clearable
              outlined
            ></v-select>
            <v-text-field
              v-model="formData.name"
              label="Name"
              outlined
              required
            ></v-text-field>

            <v-file-input
              class="col-12"
              v-model="formData.logo_dark"
              show-size
              counter
              multiple
              dense
              outlined
              accept="image/png, image/jpeg, image/bmp"
              prepend-icon="mdi-camera"
              label="Logo Dark"
              clearable
            ></v-file-input>

            <v-file-input
              class="col-12"
              v-model="formData.logo_light"
              show-size
              counter
              multiple
              dense
              outlined
              accept="image/png, image/jpeg, image/bmp"
              prepend-icon="mdi-camera"
              label="Logo Light"
              clearable
            ></v-file-input>

            <v-item-group>
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: space-evenly;
                "
              >
                <div><h3>Color 1</h3></div>
                <div>
                  <v-color-picker
                    v-model="formData.color_1"
                    class="ma-2"
                    hide-canvas
                    title="color-1"
                    mode="hexa"
                  ></v-color-picker>
                </div>
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: space-evenly;
                "
              >
                <div><h3>Color 2</h3></div>
                <div>
                  <v-color-picker
                    v-model="formData.color_2"
                    class="ma-2"
                    hide-canvas
                    title="color-1"
                    mode="hexa"
                  ></v-color-picker>
                </div>
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: space-evenly;
                "
              >
                <div><h3>Color 3</h3></div>
                <div>
                  <v-color-picker
                    v-model="formData.color_3"
                    class="ma-2"
                    hide-canvas
                    title="color-1"
                    mode="hexa"
                  ></v-color-picker>
                </div>
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: space-evenly;
                "
              >
                <div><h3>Color 4</h3></div>
                <div>
                  <v-color-picker
                    v-model="formData.color_4"
                    class="ma-2"
                    hide-canvas
                    title="color-1"
                    mode="hexa"
                  ></v-color-picker>
                </div>
              </div>
            </v-item-group>

            <v-text-field
              v-model="formData.description"
              label="Description"
              clearable
              outlined
            ></v-text-field>
            <v-select
              v-model="formData.languages"
              label="Languages"
              item-text="text"
              item-value="index"
              :items="serverData.languages"
              multiple
              clearable
              outlined
              :error-messages="languagesErrors"
              @input="$v.formData.languages.$touch()"
              @blur="$v.formData.languages.$touch()"
            ></v-select>
          </form>
          <!--end::Body-->

          <!--begin::Actions-->
          <div class="mb-4 d-flex flex-grow-1 align-end justify-center">
            <button
              type="reset"
              class="btn btn-light mr-3 px-5 py-3 ls1"
              @click="resetEditForm"
            >
              Reset
            </button>
            <button
              type="submit"
              class="btn btn-info px-5 py-3 ls1"
              @click="submitEditForm"
            >
              Submit
            </button>
          </div>
          <!--end::Actions-->
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal dialog-->
    </v-dialog>
  </div>
</template>

<script>
import { getToken } from "@/core/services/jwt.service";
import axios from "axios";
import Swal from "sweetalert2";

import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "EditItem",
  mixins: [validationMixin],
  validations: {
    formData: {
      customer_id: { required },
      name: { required },
      languages: { required },
    },
  },
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    serverData: null,
    // ckeditorType: ClassicEditor,
    formData: {
      id: null,
      customer_id: null,
      name: null,
      logo_dark: null,
      logo_light: null,
      color_1: null,
      color_2: null,
      color_3: null,
      color_4: null,
      description: null,
      languages: null,
    },
  }),
  methods: {
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      if ("url" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].url &&
          errors.push("This url is invalid");
      }
      if ("numeric" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].numeric &&
          errors.push("This must be a number");
      }
      if ("email" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].email &&
          errors.push("This email address is invalid");
      }
      return errors;
    },
    loadDataFromServer() {
      this.pageLoader(true);
      let data = { id: this.$store.getters.getItemForAction.id };
      let config = {
        method: "post",
        url: `${process.env.VUE_APP_BASE_URL}/stores/portal/stores/edit`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        data: data,
      };
      axios(config)
        .then((response) => {
          this.serverData = response.data;

          this.resetEditForm();
          this.pageLoader(false);
        })
        .catch((error) => {
          Swal.fire({
            title: "Error",
            text: `${error.response.data?.message}`,
            icon: "error",
            showConfirmButton: false,
            timer: 2500,
          });
          this.dialog = false;
          this.pageLoader(false);
        });
    },
    convertToFormData() {
      let data = new FormData();
      Object.entries(this.formData).forEach((item) => {
        if (
          (item[1] && item[0] != "logo_dark" && item[0] != "logo_light") ||
          item[1] == 0
        ) {
          if (Array.isArray(item[1])) {
            item[1].forEach((it) => data.append(`${item[0]}[]`, it));
          } else if (
            typeof item[1] === "object" &&
            item[1] !== null &&
            !Array.isArray(item[1])
          ) {
            data.append(item[0], item[1].hex);
          } else {
            data.append(item[0], item[1]);
          }
        } else if (
          item[1] &&
          (item[0] == "logo_dark" || item[0] == "logo_light")
        ) {
          data.append(item[0], item[1][0]);
        }
      });
      return data;
    },
    submitEditForm() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      } else {
        this.$v.$touch();
        this.pageLoader(true);
        let data = this.convertToFormData();
        let config = {
          method: "post",
          url: `${process.env.VUE_APP_BASE_URL}/stores/portal/stores/update`,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
          data: data,
        };
        axios(config)
          .then(() => {
            Swal.fire({
              title: "Updated",
              text: `Courier data has been updated`,
              icon: "success",
              showConfirmButton: false,
              timer: 2500,
            });
            this.toggleModal();
            this.resetEditForm();
            this.pageLoader(false);
            this.refresher();
          })
          .catch((error) => {
            if (error.response) {
              // Request made and server responded
            } else if (error.request) {
              // The request was made but no response was received
            } else {
              // Something happened in setting up the request that triggered an Error
            }
            Swal.fire({
              title: "Error",
              text: error.response.data?.message,
              icon: "error",
              showConfirmButton: false,
              timer: 2500,
            });
            this.pageLoader(false);
            // this.toggleModal();
          });
      }
    },
    resetEditForm() {
      this.$v.$reset();
      if (this.serverData) {
        let copy = { ...this.serverData.store };
        Object.entries(this.formData).forEach((localD) => {
          Object.entries(this.serverData.store).forEach((serverD) => {
            if (
              localD[0] === serverD[0] &&
              localD[0] !== "logo_dark" &&
              localD[0] !== "logo_light"
            ) {
              this.formData[localD[0]] = copy[serverD[0]];
            }
          });
        });
      } else {
        this.formData = {
          id: null,
          customer_id: null,
          logo_dark: null,
          logo_light: null,
          name: null,
          color_1: null,
          color_2: null,
          color_3: null,
          color_4: null,
          description: null,
          languages: null,
        };
      }
    },
    toggleModal() {
      if (this.dialog) {
        this.dialog = !this.dialog;
        this.serverData = null;
      } else {
        this.loadDataFromServer();
        this.dialog = !this.dialog;
      }
    },
  },
  computed: {
    isDataLoadedFromServer: function () {
      return !!this.serverData;
    },
    nameErrors() {
      return this.handleFormValidation("name", this);
    },
    customer_idErrors() {
      return this.handleFormValidation("customer_id", this);
    },
    languagesErrors() {
      return this.handleFormValidation("languages", this);
    },
  },
};
</script>

<style scoped>
.editorStyle {
  max-height: 70vh;
  overflow: hidden;
}
</style>
